import type { DefaultSettings } from "~/models/DefaultSettings"

export const INITIAL_SETTINGS_DEFAULT_VALUES: DefaultSettings = {
    show_stock: {
        enable: false,
        cant_min: 0,
    },
    contacts: {
        logo_path: "",
        team_name: "",
        phones: [],
        email: "",
        address: "",
        whatsapp: "",
        facebook: "",
        instagram: "",
        map: "",
        coordinates: "",
        favicon_path: "",
        schedule: [],
        networks: [],
        lat: "",
        long: "",
    },
    information: {
        enable_term_conditions: false,
        enable_warranty_policy: false,
        enable_return_policy: false,
        enable_shipment_policy: false,
        enable_frequent_asks: false,
        enable_about_us: false,
    },
    system_coin: "",
    base_coin: "",
    base_coin_settings: {
        exchange_rate: 1,
        symbol: "",
        position: "front",
        short_name: "",
        name: "",
    },
    coins_availables: [],
    store_type: "",
    system_has_point_of_sales: false,
    today: "",
}

export const useDefaultSettingsStore = defineStore("defaultSettings", {
    state: (): { settings: DefaultSettings } => ({
        settings: INITIAL_SETTINGS_DEFAULT_VALUES,
    }),
    actions: {
        setSettings(settings: DefaultSettings) {
            this.settings = settings
        },
    },
})
